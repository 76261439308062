[
    "C79 2019 Nissan Versa",
    "C82 (CIN) 2019 Nissan Versa",
    "C85 (COL) NISSAN VERSA",
    "C89 (DAY) Nissan Versa",
    "C93 (CLE) Kia soul",
    "C97 (CLE) 2016 Kia Soul",
    "C102 (CLE) 2009 SCION XD",
    "C104 (CLE) 2009 Chevy cobalt",
    "C108 (AKR) Toyota Camry (LPR)",
    "C111 2010 Toyota Corolla"
]
