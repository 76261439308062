{
    "container": "map",
    "accessToken": "pk.eyJ1IjoiZmFyZ2VvLXJlbGVudGxlc3MiLCJhIjoiY2xlNHNjMTltMDYwMjNucWxya3djMjhoNyJ9.UcjyE7-embWYapl176Om-A",
    "style": "mapbox://styles/fargeo-relentless/cllmggtiv00le01p2hga05dh1",
    "bounds": [
        [
            -81.44280913103417,
            41.2975762653179
        ],
        [
            -81.93210377722451,
            41.595715088178565
        ]
    ]
}
