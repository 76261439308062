{
    "sources": {
        "geolocationroute": {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": []
            }
        },
        "repo_locations": {
            "type": "vector",
            "url": "mapbox://fargeo-relentless.92hvbavv"
        },
        "apts": {
            "type": "vector",
            "url": "mapbox://fargeo-relentless.0cdsy4zs"
        },
        "vehicle_paths": {
            "type": "vector",
            "url": "mapbox://fargeo-relentless.vehicle_paths_v2"
        },
        "service_areas": {
            "type": "vector",
            "url": "mapbox://fargeo-relentless.15jz21lj"
        }
    },          
    "layers": [
        {
            "id": "geolocationrouteline",
            "type": "line",
            "source": "geolocationroute",
            "paint": {
                "line-color": "rgb(0, 153, 236)",
                "line-width": 3
            }
        },
        {
            "id": "serviceArea_ranked_day",
            "type": "fill",
            "source": "service_areas",
            "source-layer": "CensusBG2019_oh_ky_20230104_2-cnipt5",
            "filter": [">", ["get", "ServiceArea_Day_AreaRateRank"], 0.25],
            "layout": {"visibility": "none"},
            "paint": {
                "fill-color": [
                    "interpolate",
                    ["linear"],
                    ["get", "ServiceArea_Day_AreaRateRank"],
                    0,
                    "hsla(0, 0%, 0%, 0)",
                    0.5,
                    "hsl(60, 100%, 77%)",
                    0.75,
                    "hsl(48, 100%, 50%)",
                    0.9,
                    "#ff5900",
                    1,
                    "#a80000"
                ],
                "fill-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    0.5,
                    11,
                    0.5,
                    16,
                    0.25
                ],
                "fill-outline-color": "hsla(0, 0%, 0%, 0)"
            }
        },
        {
            "id": "serviceArea_ranked_night",
            "type": "fill",
            "source": "service_areas",
            "source-layer": "CensusBG2019_oh_ky_20230104_2-cnipt5",
            "filter": [">", ["get", "ServiceArea_Night_AreaRateRank"], 0.25],
            "layout": {"visibility": "none"},
            "paint": {
                "fill-color": [
                    "interpolate",
                    ["linear"],
                    ["get", "ServiceArea_Night_AreaRateRank"],
                    0,
                    "hsla(0, 0%, 0%, 0)",
                    0.5,
                    "hsl(60, 100%, 77%)",
                    0.75,
                    "hsl(48, 100%, 50%)",
                    0.9,
                    "hsl(21, 100%, 50%)",
                    1,
                    "#a80000"
                ],
                "fill-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    0.5,
                    11,
                    0.5,
                    16,
                    0.25
                ],
                "fill-outline-color": "hsla(0, 0%, 0%, 0)"
            }
        },
        {
            "id": "vehicle_paths",
            "type": "line",
            "source": "vehicle_paths",
            "source-layer": "vehicle_paths",
            "filter": ["match", ["get", "trip_age"], 0, true, false],
            "paint": {
                "line-color": [
                    "step",
                    ["get", "trip_age"],
                    "#244bf9",
                    1,
                    "#244bf9",
                    5,
                    "hsla(229, 95%, 56%, 0.75)",
                    10,
                    "hsla(229, 95%, 56%, 0.5)",
                    15,
                    "hsla(229, 95%, 56%, 0.4)",
                    20,
                    "hsla(229, 95%, 56%, 0.3)",
                    25,
                    "hsla(229, 95%, 56%, 0.2)",
                    30,
                    "hsla(229, 95%, 56%, 0.1)"
                ],
                "line-width": [
                    "step",
                    ["zoom"],
                    1,
                    7,
                    1,
                    11,
                    2,
                    12.5,
                    [
                        "step",
                        ["get", "trip_age"],
                        3,
                        1,
                        3,
                        5,
                        2.5,
                        10,
                        2,
                        20,
                        1.5,
                        25,
                        1,
                        30,
                        0.5
                    ]
                ]
            }
        },
        {
            "id": "repo_locations_day",
            "type": "heatmap",
            "source": "repo_locations",
            "source-layer": "RepoLocationsAll18_22_2023010-68n4im",
            "filter": ["match", ["get", "Shift"], ["Day"], true, false],
            "layout": {"visibility": "none"},
            "paint": {
                "heatmap-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    0,
                    12,
                    0,
                    14,
                    0.75
                ],
                "heatmap-weight": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    10,
                    0.3,
                    14,
                    1
                ],
                "heatmap-radius": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    10,
                    1,
                    15,
                    20
                ],
                "heatmap-intensity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    10,
                    0,
                    14,
                    1
                ],
                "heatmap-color": [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    0,
                    "rgba(0, 0, 255, 0)",
                    0.3,
                    "#969696",
                    0.5,
                    "#636363",
                    0.7,
                    "#ff5900",
                    0.85,
                    "#a80000"
                ]
            }
        },
        {
            "id": "repo_locations_night",
            "type": "heatmap",
            "source": "repo_locations",
            "source-layer": "RepoLocationsAll18_22_2023010-68n4im",
            "filter": ["match", ["get", "Shift"], ["Night"], true, false],
            "layout": {"visibility": "none"},
            "paint": {
                "heatmap-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    0,
                    12,
                    0,
                    14,
                    0.75
                ],
                "heatmap-weight": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    10,
                    0.3,
                    14,
                    1
                ],
                "heatmap-radius": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    10,
                    1,
                    15,
                    20
                ],
                "heatmap-intensity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    10,
                    0,
                    14,
                    1
                ],
                "heatmap-color": [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    0,
                    "rgba(0, 0, 255, 0)",
                    0.3,
                    "#969696",
                    0.5,
                    "#636363",
                    0.7,
                    "#ff5900",
                    0.85,
                    "#a80000"
                ]
            }
        },
        {
            "id": "apts_labels",
            "type": "symbol",
            "source": "apts",
            "source-layer": "ALL_Counties_Multi-Family_Lis-8tg6j6",
            "layout": {
                "text-field": ["get", "Property Name"],
                "text-size": [
                    "step",
                    ["get", "SA_TotalScore"],
                    8,
                    0.7,
                    14,
                    0.8,
                    16,
                    0.9,
                    18
                ],
                "text-anchor": "top-left",
                "text-offset": [0.3, 0]
            },
            "paint": {"text-opacity": ["step", ["zoom"], 0, 16, 1]}
        },
        {
            "id": "apts_points",
            "type": "circle",
            "source": "apts",
            "source-layer": "ALL_Counties_Multi-Family_Lis-8tg6j6",
            "layout": {},
            "paint": {
                "circle-radius": [
                    "step",
                    ["get", "SA_TotalScore"],
                    3,
                    0.7,
                    5,
                    0.8,
                    7,
                    0.9,
                    10
                ],
                "circle-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    0,
                    11,
                    0,
                    12,
                    1
                ],
                "circle-stroke-width": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    0,
                    11,
                    0,
                    12,
                    1.5
                ],
                "circle-stroke-color": "#ffffff"
            }
        }
    ]
}
